import { useEffect, useState } from "react";
import { Widgets } from "./components/Widgets";
import styles from "./styles.module.scss";
import { CampaignsChart } from "@pages/AdsAnalitics/components/Campaigns/components/CampaignChart";
import classNames from "classnames";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import { getWidgetData, WidgetData } from "./utils";
import { generateWidgets } from "./utils";
import { PerformanceTable } from "./components/Performance";
import { CampaignsTable } from "./components/CampaignTable";
import { SliderKey } from "./components/Slider";

export const Campaigns = () => {
  const { dateRange } = useDashboardStore((state) => ({
    dateRange: state.dateRange,
  }));
  const [data, setData] = useState<WidgetData>();
  const [checks, setChecks] = useState({
    sales: true,
    spend: true,
    impressions: true,
    ctr: true,
    cvr: true,
    cpc: true,
    acos: true,
    roas: true,
    orders: true,
    unitSolds: true,
    clicks: false,
  });

  const handleCheck = (key: string) => {
    let formattedKey: string;

    if (key === "Units Sold") {
      formattedKey = "unitSolds";
    } else {
      formattedKey = key.toLowerCase().replace(" ", "");
    }

    setChecks((prev) => ({ ...prev, [formattedKey]: !prev[formattedKey] }));
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await getWidgetData(dateRange);
      setData(data);
    };
    fetchData();
  }, [dateRange, checks]);

  return (
    <div className={styles.container}>
      <SliderKey />
      <div className={styles.metrics}>
        {data && (
          <Widgets
            checks={checks}
            setChecks={handleCheck}
            widgetsData={generateWidgets(data)}
          />
        )}
        <div className={classNames(styles.chart, styles.box)}>
          <CampaignsChart checks={checks} />
        </div>
      </div>
      <div className={classNames(styles.box)}>
        <h2>Performance Overview</h2>
        <PerformanceTable />
      </div>
      <div className={styles.products}>
        <CampaignsTable />
      </div>
    </div>
  );
};
