import { Api } from "src/utils/api";

import { ApiResponse } from "src/types/apiResponce";
import { SPIDER_BASE_URL } from "./spiderUrls";
import { OverviewType } from "@pages/Overview";

export class SpiderService extends Api {
  constructor() {
    super(SPIDER_BASE_URL);
  }

  public async getSpiderInfo(
    asin: string = "",
    type: OverviewType = "my-product",
  ): Promise<ApiResponse<any>> {
    const path =
      type === "my-product"
        ? "/get_product_spider_data/"
        : "/get_product_spiders_data_show_room/";

    return this.get(path, { asin });
  }

  public async getProfuctInfo(asin: string = ""): Promise<ApiResponse<any>> {
    return this.get(`/get_product_opportunities_list/`, { asin });
  }
  public async getProfuctSpiderSales(
    asin: string = "",
  ): Promise<ApiResponse<any>> {
    return this.get(`/get_product_spider_data_with_sales/`, { asin });
  }
}
