import React, { useEffect, useState, useRef } from "react";
import styles from "./styles.module.scss";
import inputArrow from "@assets/icons/inputArrow.svg";
import ProductSelectionModal from "@pages/Overview/components/ProductSelectionModal";
import { useProductStore } from "src/store/overviewProduct.state";
import { Product } from "@services/amazon/products/types";
import { useInsightsStore } from "src/store/insights/insights.state";
import { loadInsideProductData } from "src/utils/insides";
import { SpiderService } from "@services/spider/spider.services";
import { formattedKeys } from "src/utils/convertUtil";
import { OverviewType } from "@pages/Overview";

interface ProductInputProps {
  productTwo?: boolean;
  label: string;
  isProductSelected?: boolean;
  selectType?: "your" | "competitor";
  type?: OverviewType;
}

const ProductInput: React.FC<ProductInputProps> = ({
  label,
  isProductSelected,
  selectType = "your",
  productTwo = false,
  type = "my-product",
}) => {
  const {
    selectedProduct,
    selectedProductToCompare,
    setSelectedProduct,
    setSelectedProductToCompare,
  } = useProductStore();

  const {
    setProductTwoAsin,
    setProductOneSpiderValues,
    setProductOneHypotheses,
    setProductOnePhrases,
    setProductOneProblems,
    setProductOneReviews,
    setProductTwoSpiderValues,
    setProductTwoHypotheses,
    setProductTwoPhrases,
    setProductTwoProblems,
    setProductTwoReviews,
    setProductTwoSpiderTitles,
    setProductOneSpiderTitles,
  } = useInsightsStore();

  const [isModalOpen, setModalOpen] = useState(false);
  const [hasSelectedOnce, setHasSelectedOnce] = useState(false);
  const prevProductRef = useRef<Product | null>(null);

  const product =
    selectType === "your" ? selectedProduct : selectedProductToCompare;

  useEffect(() => {
    if (!product && isProductSelected === false) {
      setModalOpen(true);
    }
  }, [product, isProductSelected]);

  const openModal = () => setModalOpen(true);

  const handleProductSelect = (selectedProduct: Product) => {
    if (selectType === "competitor") {
      setSelectedProductToCompare(selectedProduct);
    } else {
      setSelectedProduct(selectedProduct);
    }
    setHasSelectedOnce(true);
    prevProductRef.current = product;
    setModalOpen(false);
  };

  const getInputValue = (product: Product | null): string => {
    if (!product) return "";
    return product.item_name || product.title || "";
  };

  useEffect(() => {
    const fetchInsights = async () => {
      if (
        hasSelectedOnce &&
        prevProductRef.current !== product &&
        product &&
        !!product.asin
      ) {
        const { hypotheses, phrases, problems, reviews } =
          await loadInsideProductData(selectedProduct);
        const spider = new SpiderService();
        const spiderDots = await spider.getSpiderInfo(product.asin, type);

        setProductTwoAsin([product.asin]);
        if (!productTwo) {
          setProductTwoSpiderTitles(formattedKeys(spiderDots));
          setProductOneSpiderValues(
            Object.values(spiderDots).map((value) =>
              parseFloat(value.toFixed(1)),
            ),
          );
          setProductOneHypotheses(hypotheses);
          setProductOnePhrases(phrases);
          setProductOneProblems(problems);
          setProductOneReviews(reviews);
        } else {
          setProductOneSpiderTitles(formattedKeys(spiderDots));
          setProductTwoSpiderValues(
            Object.values(spiderDots).map((value) =>
              parseFloat(value.toFixed(1)),
            ),
          );
          setProductTwoHypotheses(hypotheses);
          setProductTwoPhrases(phrases);
          setProductTwoProblems(problems);
          setProductTwoReviews(reviews);
        }
      }
    };

    fetchInsights();
  }, [product, productTwo, hasSelectedOnce]);

  return (
    <div className={styles.inputBox}>
      <h4 className={styles.title}>{label}</h4>
      <button onClick={openModal} className={styles.inputContainer}>
        <input
          className={styles.inputText}
          value={getInputValue(product)}
          readOnly
        />
        <div className={styles.dropdownIcon}>
          <img src={inputArrow} width={12} height={12} alt="Dropdown Icon" />
        </div>
      </button>
      <ProductSelectionModal
        productTwo={productTwo}
        isOpen={isModalOpen}
        onSelect={handleProductSelect}
        onClose={() => setModalOpen(false)}
        type={type}
      />
    </div>
  );
};

export default ProductInput;
