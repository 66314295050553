import { Product } from "@services/amazon/products/types";
import { create } from "zustand";

interface IProductState {
  selectedProduct: Product | null;
  selectedProductToCompare: Product | null;
  setSelectedProduct: (product: Product) => void;
  setSelectedProductToCompare: (product: Product) => void;
}

export const useProductStore = create<IProductState>((set) => ({
  selectedProduct: null,
  selectedProductToCompare: null,
  setSelectedProduct: (product) => set({ selectedProduct: product }),
  setSelectedProductToCompare: (product) =>
    set({ selectedProductToCompare: product }),
}));
