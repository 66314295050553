import { Carousel } from "antd";
import CampaignSliderLeft from "@assets/icons/campaignSliderLeft";
import CampaignSliderRight from "@assets/icons/campaignSliderRight";
import { columnsMock, dataMock, slides as slidesMock } from "./mock";
import { useState } from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";
import "./slider.scss";
import ModalBox from "@components/ModalBox";
import { ExpandableTable } from "@components/ExpandebleTable";
import { Key } from "antd/lib/table/interface";

export const SliderKey = () => {
  const [activeSlideId, setActiveSlideId] = useState(slidesMock[0]?.id || null);
  const [isOpen, setIsOpen] = useState(false);
  const [slides, setSlides] = useState(slidesMock);
  const [chartData, setChartData] = useState(null);
  const [columns, setColumns] = useState(null);

  const [selectedCampKeys, setSelectedCampKeys] = useState([]);
  const [selectedTargetingKeys, setSelectedTargetingKeys] = useState([]);
  const [selectedTargetKeys, setSelectedTargetKeys] = useState([]);

  const getRowSelectionFunction = () => {
    if (activeSlideId === 0) {
      return {
        selectedRowKeys: selectedCampKeys,
        onChange: (selectedRowKeys: Key[]) =>
          setSelectedCampKeys(selectedRowKeys),
      };
    }
    if (activeSlideId === 1) {
      return {
        selectedRowKeys: selectedTargetingKeys,
        onChange: (selectedRowKeys: Key[]) =>
          setSelectedTargetingKeys(selectedRowKeys),
      };
    }

    return {
      selectedRowKeys: selectedTargetKeys,
      onChange: (selectedRowKeys: Key[]) =>
        setSelectedTargetKeys(selectedRowKeys),
    };
  };

  const handleBeforeChange = (_, newIndex: number) => {
    setActiveSlideId(slides[newIndex]?.id || null);
  };

  const handleViewDetails = (id: number) => {
    setChartData(dataMock[id - 1]);
    setColumns(columnsMock[id - 1]);
    setIsOpen(true);
  };

  const onApply = () => {
    setSlides((prev) => {
      const newSlides = prev.filter((slide) => slide.id !== activeSlideId);
      setActiveSlideId(newSlides[0]?.id || null);
      return newSlides;
    });
  };

  if (!slides.length) {
    return null;
  }

  const activeSlideData = slides.find((slide) => slide.id === activeSlideId);

  return (
    <div className={styles.container}>
      <Carousel
        arrows
        dots={{
          className: classNames(styles.dots, "slick-dots-custom"),
        }}
        className="campaign-slider-custom"
        beforeChange={handleBeforeChange}
        prevArrow={
          <button>
            <CampaignSliderLeft />
          </button>
        }
        nextArrow={
          <button>
            <CampaignSliderRight />
          </button>
        }
      >
        {slides.map((slide) => (
          <div key={slide.id} className={styles.slide}>
            <div className={styles.slide__wrapper}>
              <div className={styles.slide__content}>
                <h2 className={styles.slide__title}>{slide.title}</h2>
                <p className={styles.slide__text}>{slide.content}</p>
              </div>
              <div className={styles.slide__action}>
                <button
                  className={styles.details}
                  onClick={() => {
                    handleViewDetails(slide.id);
                  }}
                >
                  View Details
                </button>
                <button className={styles.apply} onClick={onApply}>
                  Apply
                </button>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
      <ModalBox
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title="Recommendations"
        className={styles.modal}
      >
        <p>{activeSlideData?.content}</p>
        <div className={styles.table__container}>
          <div className={styles.table}>
            <ExpandableTable
              columns={columns}
              data={chartData}
              rowSelection={getRowSelectionFunction()}
              scroll={{ x: 1000, y: 55 * 9 }}
            />
          </div>
          <button className={styles.apply}>Apply 26 recommendations</button>
        </div>
      </ModalBox>
    </div>
  );
};
