import { ExpandableTable } from "@components/ExpandebleTable";
import {
  adtypeData,
  formatData,
  funnelData,
  getProductsColumns,
  productsData,
  targetData,
  targetingData,
} from "../../mock";
import { useEffect, useState } from "react";
import { TabsTypeList } from "@pages/AdsAnalitics/components/SanKey/utils";
import { TypeTab } from "@pages/AdsAnalitics/components/SanKey/Chart";
import styles from "./styles.module.scss";
import { Input } from "@components/Input";
import { Select } from "@components/Select";
import { useKeywordStore } from "src/store/keyword.state";
import Search from "@assets/icons/search";
import StarKeyword from "@assets/icons/StarKeyword";
import { getStarColor } from "@components/ExpandebleTable/KeyTooltip";
import { Checkbox } from "antd";
import { Key } from "antd/lib/table/interface";

const getTabData = (tab: TypeTab) => {
  switch (tab) {
    case "Campaigns":
      return productsData;
    case "Ad Type":
      return adtypeData;
    case "Funnel":
      return funnelData;
    case "Target Type":
      return targetData;
    case "Targeting":
      return targetingData;
    default:
      return productsData;
  }
};

const formattedKeys = (keys: string[]) => {
  return keys.map((key, index) => (
    <div className={styles.keys__container}>
      <Checkbox />
      <div className={styles.star}>
        <StarKeyword fill={getStarColor(index)} />
      </div>
      <span>{key}</span>
    </div>
  ));
};

export const CampaignsTable = () => {
  const { keyword, setKeywords } = useKeywordStore();
  const [tooltipTabsTitle, setTooltipTabsTitle] = useState("");
  const [tooltipKeysTitle, setTooltipKeysTitle] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedTab, setSelectedTab] = useState<TypeTab>("Campaigns");
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("");
  const [choosedKeyword, setChoosedKeyword] = useState("");

  const handleTabsTooltip = (title: string) => {
    setTooltipTabsTitle(title);
  };

  const handleKeysTooltip = (title: string) => {
    setTooltipKeysTitle(title);
  };

  const [data, setData] = useState(
    formatData(
      getTabData(selectedTab),
      handleTabsTooltip,
      tooltipTabsTitle,
      tooltipKeysTitle,
      handleKeysTooltip,
      keyword,
      setKeywords,
      selectedTab === "Campaigns",
    ),
  );

  useEffect(() => {
    setData(
      formatData(
        getTabData(selectedTab),
        handleTabsTooltip,
        tooltipTabsTitle,
        tooltipKeysTitle,
        handleKeysTooltip,
        keyword,
        setKeywords,
        selectedTab === "Campaigns",
      ),
    );
  }, [tooltipTabsTitle, tooltipKeysTitle, keyword, selectedTab]);

  const onSelectChange = (selectedRowKeys: Key[]) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <>
      <TabsTypeList selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      <div className={styles.filters}>
        <Input
          value={search}
          placeholder="Search a campaign..."
          onChange={setSearch}
          className={styles.input}
          icon={<Search />}
        />
        <Select
          value={filter}
          options={["All", "Active", "Inactive"]}
          onChange={setFilter}
          placeholder="Filters"
        />
        {selectedTab === "Campaigns" && (
          <Select
            value={choosedKeyword}
            options={formattedKeys(keyword.keywords)}
            onChange={setChoosedKeyword}
            className={styles.select}
            placeholder={
              <span className={styles.placeholder}>
                <StarKeyword fill="#5C5C5A" />
                Custom Keywords
              </span>
            }
          />
        )}

        <button className={styles.export__button}>EXPORT</button>
      </div>
      <ExpandableTable
        columns={getProductsColumns(selectedTab)}
        data={data}
        rowSelection={rowSelection}
        withCustomScroll
      />
    </>
  );
};
