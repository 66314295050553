import { Table } from "antd";
import "./table.scss";
import classNames from "classnames";
import { ColumnsType } from "antd/lib/table";
import { TableRowSelection } from "antd/lib/table/interface";

interface ExpandableTableProps {
  columns: ColumnsType;
  data: any[];
  rowSelection?: TableRowSelection<any>;
  className?: string;
  isLoading?: boolean;
  withCustomScroll?: boolean;
  scroll?: { x?: number; y?: number };
}

const isCheckboxDisabled = (record) => {
  if (typeof record.campaign === "string") {
    return record.campaign?.startsWith("Total");
  }
  if (typeof record.targeting === "object") {
    return record.targeting?.props.children.startsWith("Grand");
  }
  return false;
};

export const ExpandableTable = ({
  columns,
  data,
  rowSelection,
  className,
  isLoading,
  withCustomScroll,
  scroll = {
    x: 1000,
  },
}: ExpandableTableProps) => {
  return (
    <Table
      columns={columns}
      dataSource={data}
      loading={isLoading}
      expandable={{
        expandRowByClick: true,
      }}
      rowSelection={
        rowSelection && {
          ...rowSelection,
          checkStrictly: false,
          getCheckboxProps: (record) => ({
            disabled: isCheckboxDisabled(record),
          }),
        }
      }
      scroll={scroll}
      pagination={false}
      className={classNames(
        "expandable-table",
        withCustomScroll && "custom-scroll",
        className,
      )}
      key={"key"}
    />
  );
};
