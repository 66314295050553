export const slides = [
  {
    id: 1,
    title: "Missing keywords",
    content:
      "You have 26 keyword recommendations for Sponsored Products campaigns with low clickthrough rate and low conversion rate, compared to similar campaigns. Applying these keyword recommendations can increase sales.",
  },
  {
    id: 2,
    title: "No sales",
    content:
      "Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus.",
  },
  {
    id: 3,
    title: "Bids Corrections",
    content:
      "Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus.",
  },
];

export const columnsMock = [
  [
    {
      title: "Keyword Phrase",
      dataIndex: "keyword",
      key: "keyword",
      width: "428px",
    },
    {
      title: "Recommendation",
      dataIndex: "recommendation",
      key: "recommendation",
      width: "180px",
    },
    {
      title: "Search Volume",
      dataIndex: "volume",
      key: "volume",
      width: "180px",
    },
    {
      title: "Lost Sales",
      dataIndex: "lostSales",
      key: "lostSales",
      width: "180px",
    },
    {
      title: "Lost Sales %",
      dataIndex: "lostSalesProcent",
      key: "lostSalesProcent",
      width: "180px",
    },
  ],
  [
    {
      title: "Targeting",
      dataIndex: "targeting",
      key: "targeting",
      width: "428px",
    },
    {
      title: "Impressions",
      dataIndex: "impressions",
      key: "impressions",
      width: "110px",
    },
    {
      title: "CTR",
      dataIndex: "ctr",
      key: "ctr",
      width: "110px",
    },
    {
      title: "Clicks",
      dataIndex: "clicks",
      key: "clicks",
      width: "110px",
    },
    {
      title: "CVR",
      dataIndex: "cvr",
      key: "cvr",
      width: "110px",
    },
    {
      title: "CPC",
      dataIndex: "cpc",
      key: "cpc",
      width: "110px",
    },
    {
      title: "Spend",
      dataIndex: "spend",
      key: "spend",
      width: "110px",
    },
    {
      title: "Sales",
      dataIndex: "sales",
      key: "sales",
      width: "110px",
    },
  ],
  [
    {
      title: "Targeting",
      dataIndex: "targeting",
      key: "targeting",
      width: "428px",
    },
    {
      title: "Recommendation",
      dataIndex: "recommendation",
      key: "recommendation",
      width: "180px",
    },
    {
      title: "Impressions",
      dataIndex: "impressions",
      key: "impressions",
      width: "120px",
    },
    {
      title: "CTR",
      dataIndex: "ctr",
      key: "ctr",
      width: "120px",
    },
    {
      title: "Clicks",
      dataIndex: "clicks",
      key: "clicks",
      width: "120px",
    },
    {
      title: "CVR",
      dataIndex: "cvr",
      key: "cvr",
      width: "120px",
    },
    {
      title: "CPC",
      dataIndex: "cpc",
      key: "cpc",
      width: "120px",
    },
    {
      title: "Spend",
      dataIndex: "spend",
      key: "spend",
      width: "120px",
    },
    {
      title: "Sales",
      dataIndex: "sales",
      key: "sales",
      width: "120px",
    },
    {
      title: "ACOS",
      dataIndex: "ACOS",
      key: "ACOS",
      width: "120px",
    },
  ],
];

export const dataMock = [
  [
    {
      key: "1",
      keyword: "crest whitening strips",
      recommendation: "Missing Target",
      volume: "203 739",
      lostSales: "$11 276",
      lostSalesProcent: "60%",
    },
    {
      key: "2",
      keyword: "crest 3d whitestrips",
      recommendation: "Missing Target",
      volume: "44 737",
      lostSales: "$2 100",
      lostSalesProcent: "60%",
    },
    {
      key: "3",
      keyword: "teeth whitening pen",
      recommendation: "Missing Target",
      volume: "29 581",
      lostSales: "$1 248",
      lostSalesProcent: "10%",
    },
    {
      key: "4",
      keyword: "blanqueador de dientes",
      recommendation: "Missing Target",
      volume: "22 305",
      lostSales: "$262",
      lostSalesProcent: "50%",
    },
    {
      key: "5",
      keyword: "teeth whitening gel",
      recommendation: "Missing Target",
      volume: "18 172",
      lostSales: "$678",
      lostSalesProcent: "10%",
    },
    {
      key: "6",
      keyword: "crest white strips",
      recommendation: "Missing Target",
      volume: "12 946",
      lostSales: "$758",
      lostSalesProcent: "60%",
    },
    {
      key: "7",
      keyword: "purple toothpaste for teeth whitening",
      recommendation: "Missing Target",
      volume: "10 715",
      lostSales: "$496",
      lostSalesProcent: "10%",
    },
    {
      key: "8",
      keyword: "white teeth",
      recommendation: "Missing Target",
      volume: "6 978",
      lostSales: "$114",
      lostSalesProcent: "60%",
    },
    {
      key: "9",
      keyword: "teeth whitening toothpaste",
      recommendation: "Missing Target",
      volume: "5 842",
      lostSales: "$201",
      lostSalesProcent: "10%",
    },
    {
      key: "10",
      keyword: "crest white strips professional strength",
      recommendation: "Missing Target",
      volume: "4 479",
      lostSales: "$335",
      lostSalesProcent: "60%",
    },
    {
      key: "11",
      keyword: "whitestrips",
      recommendation: "Missing Target",
      volume: "3 819",
      lostSales: "$195",
      lostSalesProcent: "80%",
    },
    {
      key: "12",
      keyword: "best teeth whitening products 5 star",
      recommendation: "Missing Target",
      volume: "3 728",
      lostSales: "$127",
      lostSalesProcent: "50%",
    },
    {
      key: "13",
      keyword: "crest whitestrips",
      recommendation: "Missing Target",
      volume: "3 640",
      lostSales: "$195",
      lostSalesProcent: "60%",
    },
    {
      key: "14",
      keyword: "whitening mouthwash",
      recommendation: "Missing Target",
      volume: "3 619",
      lostSales: "$282",
      lostSalesProcent: "10%",
    },
    {
      key: "15",
      keyword: "teeth whiteners best sellers",
      recommendation: "Missing Target",
      volume: "3 584",
      lostSales: "$121",
      lostSalesProcent: "40%",
    },
    {
      key: "16",
      keyword: "crest teeth whitening strips",
      recommendation: "Missing Target",
      volume: "3 448",
      lostSales: "$188",
      lostSalesProcent: "60%",
    },
    {
      key: "17",
      keyword: "crest whitening strips sensitive teeth",
      recommendation: "Missing Target",
      volume: "3 433",
      lostSales: "$215",
      lostSalesProcent: "80%",
    },
    {
      key: "18",
      keyword: "crest whitening",
      recommendation: "Missing Target",
      volume: "3 417",
      lostSales: "$107",
      lostSalesProcent: "20%",
    },
    {
      key: "19",
      keyword: "3d white crest whitestrips",
      recommendation: "Missing Target",
      volume: "3 108",
      lostSales: "$114",
      lostSalesProcent: "60%",
    },
    {
      key: "20",
      keyword: "purple teeth whitening",
      recommendation: "Missing Target",
      volume: "3 054",
      lostSales: "$121",
      lostSalesProcent: "10%",
    },
    {
      key: "21",
      keyword: "inopro teeth whitening strips",
      recommendation: "Missing Target",
      volume: "2 851",
      lostSales: "$141",
      lostSalesProcent: "100%",
    },
    {
      key: "22",
      keyword: "whitening pen",
      recommendation: "Missing Target",
      volume: "2 771",
      lostSales: "$114",
      lostSalesProcent: "10%",
    },
    {
      key: "23",
      keyword: "teeth whitner",
      recommendation: "Missing Target",
      volume: "2 404",
      lostSales: "$80",
      lostSalesProcent: "40%",
    },
    {
      key: "24",
      keyword: "whitening strips crest",
      recommendation: "Missing Target",
      volume: "2 143",
      lostSales: "$87",
      lostSalesProcent: "60%",
    },
    {
      key: "25",
      keyword: "tooth whitener",
      recommendation: "Missing Target",
      volume: "1 920",
      lostSales: "$74",
      lostSalesProcent: "40%",
    },
    {
      key: "26",
      keyword: "crest sensitive whitening strips",
      recommendation: "Missing Target",
      volume: "1 859",
      lostSales: "$114",
      lostSalesProcent: "60%",
    },
  ],
  [
    {
      key: "1",
      targeting: 'audience="IM - Oral Care Products"',
      impressions: "72 123",
      ctr: "0.15%",
      clicks: "111",
      cvr: "0%",
      cpc: "$0.81",
      spend: "$90",
      sales: "$0",
      children: [
        {
          key: "1-1",
          targeting: "Nyle - SD - B0B5HN65QQ - IM LS",
          impressions: "72 123",
          ctr: "0.15%",
          clicks: "111",
          cvr: "0%",
          cpc: "$0.81",
          spend: "$90",
          sales: "$0",
        },
      ],
    },
    {
      key: "2",
      targeting: "similar-product",
      impressions: "94 921",
      ctr: "0.09%",
      clicks: "83",
      cvr: "0%",
      cpc: "$0.92",
      spend: "$76",
      sales: "$0",
      children: [
        {
          key: "2-1",
          targeting: "Nyle - SD - B0B5HN65QQ - CONTEXTUAL",
          impressions: "94 921",
          ctr: "0.09%",
          clicks: "83",
          cvr: "0%",
          cpc: "$0.92",
          spend: "$76",
          sales: "$0",
        },
      ],
    },
    {
      key: "3",
      targeting: "teeth whitening kits",
      impressions: "46 945",
      ctr: "0.04%",
      clicks: "18",
      cvr: "0%",
      cpc: "$2.79",
      spend: "$50",
      sales: "$0",
      children: [
        {
          key: "3-1",
          targeting: "Nyle - SP - B0B5HN65QQ - GEKW MID EXACT",
          impressions: "46 945",
          ctr: "0.04%",
          clicks: "18",
          cvr: "0%",
          cpc: "$2.79",
          spend: "$50",
          sales: "$0",
        },
      ],
    },
    {
      key: "4",
      targeting: "views=(similar-product lookback=90)",
      impressions: "26 216",
      ctr: "0.14%",
      clicks: "37",
      cvr: "0%",
      cpc: "$0.94",
      spend: "$35",
      sales: "$0",
      children: [
        {
          key: "4-1",
          targeting: "Nyle - SD - B0B5HN65QQ - REMARKETING",
          impressions: "26 216",
          ctr: "0.14%",
          clicks: "37",
          cvr: "0%",
          cpc: "$0.94",
          spend: "$35",
          sales: "$0",
        },
      ],
    },
    {
      key: "5",
      targeting: 'audience="IM - Teeth Whitening Products"',
      impressions: "25 184",
      ctr: "0.17%",
      clicks: "43",
      cvr: "0%",
      cpc: "$0.77",
      spend: "$33",
      sales: "$0",
      children: [
        {
          key: "5-1",
          targeting: "Nyle - SD - B0B5HN65QQ - IM LS",
          impressions: "25 184",
          ctr: "0.17%",
          clicks: "43",
          cvr: "0%",
          cpc: "$0.77",
          spend: "$33",
          sales: "$0",
        },
      ],
    },
    {
      key: "6",
      targeting: "views=(similar-product lookback=60)",
      impressions: "22 917",
      ctr: "0.14%",
      clicks: "32",
      cvr: "0%",
      cpc: "$0.92",
      spend: "$30",
      sales: "$0",
      children: [
        {
          key: "6-1",
          targeting: "Nyle - SD - B0B5HN65QQ - REMARKETING",
          impressions: "22 917",
          ctr: "0.14%",
          clicks: "32",
          cvr: "0%",
          cpc: "$0.92",
          spend: "$30",
          sales: "$0",
        },
      ],
    },
    {
      key: "7",
      targeting: "views=(exact-product lookback=30)",
      impressions: "22 727",
      ctr: "0.11%",
      clicks: "26",
      cvr: "0%",
      cpc: "$0.94",
      spend: "$25",
      sales: "$0",
      children: [
        {
          key: "7-1",
          targeting: "Nyle - SD - B0B5HN65QQ - REMARKETING",
          impressions: "22 727",
          ctr: "0.11%",
          clicks: "26",
          cvr: "0%",
          cpc: "$0.94",
          spend: "$25",
          sales: "$0",
        },
      ],
    },
    {
      key: "8",
      targeting: "views=(exact-product lookback=60)",
      impressions: "22 230",
      ctr: "0.09%",
      clicks: "21",
      cvr: "0%",
      cpc: "$0.74",
      spend: "$16",
      sales: "$0",
      children: [
        {
          key: "8-1",
          targeting: "Nyle - SD - B0B5HN65QQ - REMARKETING",
          impressions: "22 230",
          ctr: "0.09%",
          clicks: "21",
          cvr: "0%",
          cpc: "$0.74",
          spend: "$16",
          sales: "$0",
        },
      ],
    },
    {
      key: "9",
      targeting: 'category="Teeth Whitening Strips"',
      impressions: "28 733",
      ctr: "0.03%",
      clicks: "10",
      cvr: "0%",
      cpc: "$0.79",
      spend: "$8",
      sales: "$0",
      children: [
        {
          key: "9-1",
          targeting: "Nyle - SD - B0B5HN65QQ - CONTEXTUAL",
          impressions: "28 733",
          ctr: "0.03%",
          clicks: "10",
          cvr: "0%",
          cpc: "$0.79",
          spend: "$8",
          sales: "$0",
        },
      ],
    },
    {
      key: "10",
      targeting: "purchases=(related-product lookback=90)",
      impressions: "6 620",
      ctr: "0.30%",
      clicks: "20",
      cvr: "0%",
      cpc: "$0.28",
      spend: "$6",
      sales: "$0",
      children: [
        {
          key: "10-1",
          targeting: "Nyle - SD - B0B5HN65QQ - REMARKETING",
          impressions: "6 620",
          ctr: "0.30%",
          clicks: "20",
          cvr: "0%",
          cpc: "$0.28",
          spend: "$6",
          sales: "$0",
        },
      ],
    },
    {
      key: "11",
      targeting: "views=(similar-product lookback=30)",
      impressions: "14 921",
      ctr: "0.10%",
      clicks: "15",
      cvr: "0%",
      cpc: "$0.34",
      spend: "$5",
      sales: "$0",
      children: [
        {
          key: "11-1",
          targeting: "Nyle - SD - B0B5HN65QQ - REMARKETING",
          impressions: "14 921",
          ctr: "0.10%",
          clicks: "15",
          cvr: "0%",
          cpc: "$0.34",
          spend: "$5",
          sales: "$0",
        },
      ],
    },
    {
      key: "12",
      targeting: <p style={{ paddingLeft: "38px" }}>Grand Total</p>,
      impressions: "383 537",
      ctr: "0.11%",
      clicks: "416",
      cvr: "0%",
      cpc: "$0.90",
      spend: "$374",
      sales: "$0",
    },
  ],
  [
    {
      key: "1",
      targeting: 'category="Teeth Whitening Strips"',
      recommendation: "Increase bid by 20%",
      impressions: "699 743",
      ctr: "0.42%",
      clicks: "2 745",
      cvr: "27.5%",
      cpc: "$1.24",
      spend: "$3 414",
      sales: "$21 850",
      ACOS: "16%",
      children: [
        {
          key: "1-1",
          targeting: "product targeting category",
          recommendation: "Increase bid by 20%",
          impressions: "298 832",
          ctr: "0.74%",
          clicks: "2 218",
          cvr: "29.1%",
          cpc: "$1.06",
          spend: "$2 343",
          sales: "$18 776",
          ACOS: "12%",
        },
        {
          key: "1-2",
          targeting: "Nyle - SP - B0B5HN65QQ-CAT STRIPS",
          recommendation: "Increase bid by 20%",
          impressions: "360 911",
          ctr: "0.15%",
          clicks: "527",
          cvr: "20.5%",
          cpc: "$2.03",
          spend: "$1 071",
          sales: "$3 073",
          ACOS: "35%",
        },
      ],
    },
    {
      key: "2",
      targeting: 'asin="B00AAHAW0O"',
      recommendation: "Increase bid by 20%",
      impressions: "132 396",
      ctr: "1.00%",
      clicks: "1 330",
      cvr: "26.0%",
      cpc: "$1.86",
      spend: "$2 608",
      sales: "$10 268",
      ACOS: "25%",
      children: [
        {
          key: "2-1",
          targeting: "Nyle - SP - B0B5HN65QQ - CORP STRIPS NOT E...",
          recommendation: "Increase bid by 20%",
          impressions: "113 419",
          ctr: "0.77%",
          clicks: "870",
          cvr: "26.2%",
          cpc: "$2.14",
          spend: "$1 861",
          sales: "$6 888",
          ACOS: "28%",
        },
        {
          key: "2-2",
          targeting: "Nyle - SP - B0B5HN65QQ - CORP KIT NOT EXP",
          recommendation: "Increase bid by 20%",
          impressions: "18 977",
          ctr: "2.42%",
          clicks: "460",
          cvr: "25.7%",
          cpc: "$1.63",
          spend: "$748",
          sales: "$3 680",
          ACOS: "20%",
        },
      ],
    },
    {
      key: "3",
      targeting: 'asin="B077VCG84F"',
      recommendation: "Increase bid by 20%",
      impressions: "57 912",
      ctr: "2.77%",
      clicks: "1 602",
      cvr: "28.9%",
      cpc: "$1.31",
      spend: "$2 101",
      sales: "$13 472",
      ACOS: "16%",
      children: [
        {
          key: "3-1",
          targeting: "Nyle - SP - B0B5HN65QQ - CORP STRIPS NOT E...",
          recommendation: "Increase bid by 20%",
          impressions: "57 905",
          ctr: "2.76%",
          clicks: "1 600",
          cvr: "28.9%",
          cpc: "$1.31",
          spend: "$2 100",
          sales: "$13 472",
          ACOS: "16%",
        },
        {
          key: "3-2",
          targeting: "Nyle - SP - B0BCXY5C2H - CORP STRIPS EXACT",
          recommendation: "Increase bid by 20%",
          impressions: "7",
          ctr: "28.57%",
          clicks: "2",
          cvr: "0%",
          cpc: "$0.78",
          spend: "$2",
          sales: "$0",
          ACOS: "$0",
        },
      ],
    },
    {
      key: "4",
      targeting: "teeth whitening strips",
      recommendation: "Increase bid by 20%",
      impressions: "155 102",
      ctr: "0.21%",
      clicks: "333",
      cvr: "29.4%",
      cpc: "$2.73",
      spend: "$910",
      sales: "$3 060",
      ACOS: "30%",
      children: [
        {
          key: "4-1",
          targeting: "Nyle - SP - B0B5HN65QQ - GEKW HIGH EXACT",
          recommendation: "Increase bid by 20%",
          impressions: "155 102",
          ctr: "0.21%",
          clicks: "333",
          cvr: "29.4%",
          cpc: "$2.73",
          spend: "$910",
          sales: "$3 060",
          ACOS: "30%",
        },
      ],
    },
    {
      key: "5",
      targeting: "whitening strip",
      recommendation: "Increase bid by 20%",
      impressions: "68 454",
      ctr: "0.30%",
      clicks: "204",
      cvr: "26.6%",
      cpc: "$2.56",
      spend: "$522",
      sales: "$1 610",
      ACOS: "32%",
      children: [
        {
          key: "5-1",
          targeting: "Nyle - SP - B0B5HN65QQ - GEKW HIGH EXACT",
          recommendation: "Increase bid by 20%",
          impressions: "68 454",
          ctr: "0.30%",
          clicks: "204",
          cvr: "26.6%",
          cpc: "$2.56",
          spend: "$522",
          sales: "$1 610",
          ACOS: "32%",
        },
      ],
    },
    {
      key: "6",
      targeting: "teeth whitening strip",
      recommendation: "Increase bid by 20%",
      impressions: "184 697",
      ctr: "0.09%",
      clicks: "175",
      cvr: "21.1%",
      cpc: "$2.65",
      spend: "$463",
      sales: "$1 129",
      ACOS: "41%",
      children: [
        {
          key: "6-1",
          targeting: "Nyle - SP - B0B5HN65QQ - GEKW HIGH EXACT",
          recommendation: "Increase bid by 20%",
          impressions: "184 697",
          ctr: "0.09%",
          clicks: "175",
          cvr: "21.1%",
          cpc: "$2.65",
          spend: "$463",
          sales: "$1 129",
          ACOS: "41%",
        },
      ],
    },
    {
      key: "7",
      targeting: "tooth whitening strips",
      recommendation: "Increase bid by 20%",
      impressions: "90 283",
      ctr: "0.07%",
      clicks: "67",
      cvr: "23.9%",
      cpc: "$1.99",
      spend: "$134",
      sales: "$370",
      ACOS: "36%",
      children: [
        {
          key: "7-1",
          targeting: "Nyle - SP - B0B5HN65QQ - GEKW HIGH EXACT",
          recommendation: "Increase bid by 20%",
          impressions: "90 402",
          ctr: "0.06%",
          clicks: "56",
          cvr: "25.0%",
          cpc: "$2.02",
          spend: "$113",
          sales: "$319",
          ACOS: "35%",
        },
        {
          key: "7-1",
          targeting: "Nyle - SP - B0B5HN65QQ - GEKW HIGH EXACT",
          recommendation: "Increase bid by 20%",
          impressions: "2 881",
          ctr: "0,38%",
          clicks: "11",
          cvr: "19.2%",
          cpc: "$1.88",
          spend: "$21",
          sales: "$51",
          ACOS: "41%",
        },
      ],
    },
    {
      key: "8",
      targeting: 'asin="B00V7F6MHR"',
      recommendation: "Increase bid by 20%",
      impressions: "11 257",
      ctr: "0.57%",
      clicks: "64",
      cvr: "34.4%",
      cpc: "$2.05",
      spend: "$131",
      sales: "$598",
      ACOS: "22%",
      children: [
        {
          key: "8-1",
          targeting: "Nyle - SP - B0B5HN65QQ - CORP KIT NOT EXP",
          recommendation: "Increase bid by 20%",
          impressions: "11 067",
          ctr: "0.73%",
          clicks: "45",
          cvr: "33.3%",
          cpc: "$1.67",
          spend: "$75",
          sales: "$413",
          ACOS: "18%",
        },
        {
          key: "8-2",
          targeting: "Nyle - SP - B0B5HN65QQ - CORP STRIPS NOT E...",
          recommendation: "Increase bid by 20%",
          impressions: "2 075",
          ctr: "0.66%",
          clicks: "19",
          cvr: "36.8%",
          cpc: "$2.95",
          spend: "$56",
          sales: "$184",
          ACOS: "30%",
        },
        {
          key: "8-3",
          targeting: "Nyle - SP - B0B5HN65QQ - CORP STRIPS",
          recommendation: "Increase bid by 20%",
          impressions: "0",
          ctr: "0.00%",
          clicks: "0",
          cvr: "0%",
          cpc: "$0",
          spend: "$0",
          sales: "$0",
          ACOS: "Text",
        },
        {
          key: "8-4",
          targeting: "Nyle - SP - B0BCXY5C2H - CORP STRIPS",
          recommendation: "Increase bid by 20%",
          impressions: "0",
          ctr: "0.00%",
          clicks: "0",
          cvr: "0%",
          cpc: "$0",
          spend: "$0",
          sales: "$0",
          ACOS: "Text",
        },
      ],
    },
  ],
];
