import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import ModalBox from "@components/ModalBox";
import { useProductsStore } from "src/store/product.state";
import { AmazonService } from "@services/amazon/amazon.service";
import styles from "./styles.module.scss";
import SearchIcon from "@assets/icons/SearchIcon.svg";
import CheckIcon from "@assets/icons/CheckIcon.svg";
import dropDownArrow from "@assets/icons/inputArrow.svg";
import ProductCode from "@components/ProductCode";
import { Product } from "@services/amazon/products/types";
import { useProductStore } from "src/store/overviewProduct.state";
import { OverviewType } from "@pages/Overview";

type ProductTab = "myProducts" | "allProducts";
interface ProductModalProps {
  isOpen: boolean;
  onClose?: () => void;
  onSelect: (product: Product, searchTerm: string) => void;
  productTwo?: boolean;
  type: OverviewType;
}

const ProductSelectionModal: React.FC<ProductModalProps> = ({
  isOpen,
  onClose,
  onSelect,
  productTwo = false,
  type,
}) => {
  const tabs = (
    type === "my-product"
      ? [
          { tab: "myProducts", label: "My Products" },
          { tab: "allProducts", label: "All Products" },
        ]
      : [{ tab: "allProducts", label: "All Products" }]
  ) satisfies { tab: ProductTab; label: string }[];

  const [activeTab, setActiveTab] = useState<ProductTab>(tabs[0].tab);
  const [activeButton, setActiveButton] = useState(8);
  const [selectedOneProduct, setSelectedOneProduct] = useState<Product | null>(
    null,
  );
  const [searchTerm, setSearchTerm] = useState("");

  const { productsOwn = [], productsPublic = [] } = useProductsStore();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const params = {
          asin: searchTerm,
          limit: activeButton,
        };

        if (searchTerm) {
          if (activeTab === "myProducts") {
            await AmazonService.products.searchOwnProducts(params);
          } else {
            await AmazonService.products.searchPublicProducts(params);
          }
        } else {
          if (activeTab === "myProducts") {
            await AmazonService.products.getOwnProducts(params);
          } else {
            await AmazonService.products.getPublicProducts(params);
          }
        }
      } catch (err) {
        console.error("Error fetching products:", err);
      }
    };

    if (isOpen) {
      fetchProducts();
    }
  }, [isOpen, activeTab, activeButton, searchTerm]);

  const productsToDisplay =
    activeTab === "myProducts" ? productsOwn : productsPublic;

  const handleSelect = (product: Product) => {
    if (productTwo) {
      useProductStore.getState().setSelectedProductToCompare(product);
      onSelect(product, searchTerm);
    } else {
      setSelectedOneProduct(product);
      useProductStore.getState().setSelectedProduct(product);
      onSelect(product, searchTerm);
    }
  };

  const renderTabButton = (tab: ProductTab, label: string) => (
    <button
      key={tab}
      className={classNames(styles.tabButton, {
        [styles.active]: activeTab === tab,
      })}
      onClick={() => setActiveTab(tab)}
    >
      {label.toUpperCase()}
    </button>
  );

  const renderSwitchButton = (value: number) => (
    <button
      key={value}
      className={classNames(styles.switchButton, {
        [styles.active]: activeButton === value,
      })}
      onClick={() => setActiveButton(value)}
    >
      {value}
    </button>
  );

  return (
    <ModalBox
      isOpen={isOpen}
      onClose={onClose ? onClose : () => {}}
      title="Product Selection"
      hideCloseButton={!onClose}
    >
      <div className={styles.tabButtons}>
        <div className={styles.tabButtonsWrapper}>
          {tabs.map(({ tab, label }) => renderTabButton(tab, label))}
        </div>
      </div>
      <div className={styles.searchBox}>
        <input
          type="text"
          placeholder="Search by product name or ASIN"
          className={styles.searchInput}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button className={styles.searchButton}>
          <img src={SearchIcon} alt="Search" />
        </button>
      </div>
      <div className={styles.sortSection}>
        <div className={styles.sortSelect}>
          <span className={styles.sortText}>SORT ALPHABETICALLY</span>
          <img
            src={dropDownArrow}
            alt="Sort"
            className={styles.dropDownArrow}
          />
        </div>
        <div className={styles.switchButtons}>
          {[8, 16, 32].map(renderSwitchButton)}
        </div>
      </div>
      <div className={styles.productGridContainer}>
        <div className={styles.productGrid}>
          {Array.isArray(productsToDisplay) &&
            productsToDisplay.map((product, index) => (
              <div
                key={`${activeTab}-${product.asin}-${index}`}
                className={styles.productCard}
              >
                <img
                  src={product.image_link || product.picture_url || ""}
                  alt="Product image"
                  className={styles.productImage}
                  width={124}
                  height={124}
                />
                <ProductName name={product.item_name || product.title} />
                <div className={styles.productDetails}>
                  <ProductCode code={product.asin} />
                </div>
                <button
                  className={classNames(styles.selectButton, {
                    [styles.selected]:
                      selectedOneProduct?.asin === product.asin,
                  })}
                  onClick={() => handleSelect(product)}
                >
                  {selectedOneProduct?.asin === product.asin ? (
                    <>
                      <img
                        src={CheckIcon}
                        alt="Selected"
                        className={styles.checkIcon}
                      />
                      SELECT
                    </>
                  ) : (
                    "SELECT"
                  )}
                </button>
              </div>
            ))}
        </div>
      </div>
    </ModalBox>
  );
};

const ProductName: React.FC<{ name: string }> = ({ name }) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const productNameRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    const element = productNameRef.current;
    if (element) {
      setIsTruncated(element.scrollHeight > element.clientHeight);
    }
  }, [name]);

  return (
    <p
      ref={productNameRef}
      className={classNames(styles.productName, {
        [styles.truncated]: isTruncated,
      })}
    >
      {name}
    </p>
  );
};

export default ProductSelectionModal;
