import React from "react";
import styles from "../styles.module.scss";
import ProductCode from "@components/ProductCode";
import ProductInput from "@components/ProductInput";
import { Product } from "@services/amazon/products/types";
import { OverviewType } from "@pages/Overview";

interface MockDataType {
  type: string;
  name: string;
  price: string;
  rating: number;
  reviews: Record<string, number>;
  image: string;
  code: string;
}

interface ProductCardProps {
  productTwo?: boolean;
  product: Product | null;
  label: string;
  selectType: "your" | "competitor";
  mockData: MockDataType;
  type: OverviewType;
}

const ProductCard: React.FC<ProductCardProps> = ({
  productTwo = false,
  product,
  label,
  selectType,
  mockData,
  type,
}) => {
  const getStarReviews = (reviews: Record<string, number>) => {
    const starRatings = [
      { label: "5 STAR", value: reviews["5_star"] },
      { label: "4 STAR", value: reviews["4_star"] },
      { label: "3 STAR", value: reviews["3_star"] },
      { label: "2 STAR", value: reviews["2_star"] },
      { label: "1 STAR", value: reviews["1_star"] },
    ];
    return starRatings;
  };

  const rating = mockData.rating;
  const reviews = mockData.reviews;

  return (
    <div className={styles.product}>
      <div className={styles.productCode}>
        <ProductInput
          label={label}
          selectType={selectType}
          productTwo={productTwo}
          type={type}
        />
        <ProductCode code={product?.asin || mockData.code} />
      </div>
      <div className={styles.imgRateContainer}>
        <div className={styles.image}>
          <img
            src={product?.image_link ?? product?.picture_url ?? mockData.image}
            alt={product?.title ?? mockData.name}
          />
        </div>
        <div className={styles.details}>
          <span className={styles.price}>
            ${product?.price ?? mockData.price}
          </span>
          <div className={styles.rating}>
            {[...Array(5)].map((_star, index) => {
              const ratingValue = index + 1;
              return (
                <span key={index} className={styles.star}>
                  {ratingValue <= rating ? "★" : "☆"}
                </span>
              );
            })}
            <span className={styles.ratingValue}>{rating}.0</span>
          </div>
          <ul className={styles.reviews}>
            {getStarReviews(reviews).map((review, index) => (
              <li key={index} className={styles.reviewItem}>
                <span className={styles.reviewLabel}>{review.label}</span>
                <div className={styles.reviewBar}>
                  <div
                    className={styles.reviewFill}
                    style={{ width: `${review.value}%` }}
                  ></div>
                </div>
                <span className={styles.reviewPercentage}>{review.value}%</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
